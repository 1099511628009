// @ts-nocheck
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { getUserInfo } from "../../../../store/actions/sota-tiers";
import NoStakeUser, { LearnMoreTier } from "../NoStakeUser";
import TierInfomation from "../TierInfomation";
import useStyles from "./style";

const ManageTier = (props: any) => {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { showTierInfo } = props;

  const { connectedAccount } = useAuth();

  useEffect(() => {
    connectedAccount && dispatch(getUserInfo(connectedAccount));
  }, [connectedAccount, dispatch]);

  return (
    <div className={styles.content}>
      {showTierInfo ? (
        <div className={styles.stakingContent}>
          <p className={styles.title}>Staking Information</p>
          <p className={styles.contentUnderTitle}>
            You can either stake $RWA to achieve RWA Tier Points
          </p>

          <div className={styles.stakingInfo}>
            <TierInfomation
              title="Stake Now"
              action={() => history.push("/staking-pools?benefit=ido-only")}
            />
          </div>
        </div>
      ) : (
        <></>
      )}

      <LearnMoreTier />
    </div>
  );
};

export default ManageTier;
