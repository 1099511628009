import { Box } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import Skeleton from "@material-ui/lab/Skeleton";
import { BigNumber } from "ethers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import useDetailListStakingPool from "../../../StakingPools/hook/useDetailListStakingPool";
import { StakingCard } from "../StakingCard";
import commonStyle from "../../../../styles/CommonStyle";
import useStyles from "./style";
import LoadingTable from "../../../../components/Base/LoadingTable";

const StakingPools: React.FC<any> = (props: any) => {
  const styles = useStyles();
  const common = commonStyle();

  const { data: poolsList, loading: loadingGetPool } =
    useFetch<any>(`/staking-pool`);
  const { linearPools, loading } = useDetailListStakingPool(poolsList);
  const [displayPools, setDisplayPools] = useState<Array<any>>([]);
  useEffect(() => {
    if (!linearPools) return;

    let listLinear = Object.values(linearPools);

    listLinear = listLinear
      .filter(
        (e: any) =>
          Number(e?.endJoinTime) > moment().unix() &&
          (BigNumber.from(e?.cap).eq(BigNumber.from("0")) ||
            BigNumber.from(e?.cap)
              .sub(BigNumber.from(e?.totalStaked))
              .gt(BigNumber.from("0")))
      )
      .slice(0, 4);

    setDisplayPools(listLinear);
  }, [linearPools]);

  const renderLoading = () => {
    return (
      <div className={styles.loadingContainer}>
        <LoadingTable />
      </div>
    );
  };

  return (
    <>
      {loadingGetPool || loading ? (
        renderLoading()
      ) : (
        <div className={styles.container}>
          {displayPools.map((pool: any, index: number) => {
            return <StakingCard cardInfo={pool} key={index} />;
          })}
        </div>
      )}
    </>
  );
};

export default withWidth()(StakingPools);
