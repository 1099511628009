import useStyles from "./style";

const Partners = () => {
  const styles = useStyles();
  const slides = [
    "/images/landing/gemhead.png",
    "/images/landing/duckdao.png",
    "https://cdn.prod.website-files.com/65dae99a3d4f02927b6fb715/66c206fb234911f96b142897_logo.svg",
    "/images/landing/kairon.png",
    "/images/landing/maven.png",
    "/images/landing/decubate.png",
    "/images/landing/capital.png",
    "/images/landing/weco.png",
    "/images/landing/azaventure.png",
    "/images/landing/awc.png",
    "/images/landing/castrum.png",
  ];

  return (
    <div className={styles.main}>
      <div className={styles.slide}>
        <div className="home-slide">
          {[...slides, ...slides]?.map((slide, index) => {
            return (
              <img key={index} style={{ height: 48 }} src={slide} alt="slide" />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Partners;
