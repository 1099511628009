import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import useStyles from "./styles";
import ButtonLink from "../ButtonLink";
import { useState } from "react";
import {
  LEARN_LINKS,
  StyledMenu,
} from "../HeaderDefaultLayout/HeaderDefaultLayout";
import { MenuItem } from "@material-ui/core";
import { isNumber } from "lodash";
import { Link, NavLink } from "react-router-dom";

const FooterDefaultLayout = () => {
  const styles = useStyles();
  const [anchorLearnMenu, setAnchorLearnMenu] = useState<null | HTMLElement>(
    null
  );
  const isOpenLearnMenu = Boolean(anchorLearnMenu);

  const openLearnMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorLearnMenu(event.currentTarget);
  };

  const handleCloseLearnMenu = (index: number) => {
    setAnchorLearnMenu(null);
    // @ts-ignore
    isNumber(index) && window.open(LEARN_LINKS[index].url, "_blank").focus();
  };

  return (
    <div className={styles.container}>
      <div className={styles.footerTop}>
        <div className={styles.footerTopLeft}>
          <NavLink to={"/"}>
            <img
              src="/images/landing/logo_white.png"
              width={159}
              height={66}
              alt="logo"
            />
          </NavLink>
          <div className={styles.footerNav}>
            <div>
              <Link to={"/pools"}>Launches</Link>
            </div>
            <div>
              <Link to={"/staking-pools"}>Staking</Link>
            </div>
            <div className={styles.btnLearnFooter} onClick={openLearnMenu}>
              <div>Learn</div>
              <ArrowDropDown />
            </div>
            <StyledMenu
              id="learn-menu"
              anchorEl={anchorLearnMenu}
              className={styles.learnMenu}
              open={isOpenLearnMenu}
              horizontal={-50}
              vertical={250}
              onClose={handleCloseLearnMenu}
            >
              {LEARN_LINKS.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    href={item.url}
                    target={"_blank"}
                    onClick={() => handleCloseLearnMenu(index)}
                    disableRipple
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
            </StyledMenu>
          </div>
        </div>
        <div className={styles.footerTopRight}>
          <div className={styles.followUs}>Follow us</div>
          <div className={styles.social}>
            <a href="https://t.me/RWA_Inc" target={"_blank"} rel="noreferrer">
              <div>
                <img
                  src="/images/landing/telegram.svg"
                  width={12}
                  height={12}
                  alt="telegram"
                />
              </div>
            </a>
            <a href="https://x.com/rwa_inc_" target={"_blank"} rel="noreferrer">
              <div>
                <img
                  src="/images/landing/twitter.svg"
                  width={12}
                  height={12}
                  alt="telegram"
                />
              </div>
            </a>
            <a
              href="https://medium.com/@RWA_Inc_"
              target={"_blank"}
              rel="noreferrer"
            >
              <div>
                <img
                  src="/images/landing/medium.svg"
                  width={12}
                  height={12}
                  alt="telegram"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.foooterLine} />
      <div className={styles.footerBot}>
        <p>Copyright©2024. All rights reserved.</p>
        <div>
          <span>
            <a
              href="https://cdn.prod.website-files.com/65dae99a3d4f02927b6fb715/6687885118d8067532b32b76_WEBSITE%20DISCLAIMER%20%26%20Privacy%20Policy-%20RWA%20.pdf"
              target={"_blank"}
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </span>
          <span> | </span>
          <span>
            <a
              href="https://cdn.prod.website-files.com/65dae99a3d4f02927b6fb715/6687885118d8067532b32b76_WEBSITE%20DISCLAIMER%20%26%20Privacy%20Policy-%20RWA%20.pdf"
              target={"_blank"}
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <span> | </span>
          <span>
            <a
              href="https://docs.rwa.inc/socials"
              target={"_blank"}
              rel="noreferrer"
            >
              Help Center
            </a>
          </span>
          <span> | </span>
          <span>
            <a href="mailto:support@rwa.inc">support@rwa.inc</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterDefaultLayout;
